.home-background {
  background-image: url(../../img/sfondo-header.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 80px 0;
  height: 100vh;
}

.home {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.media-home {
  margin: 0.5rem;
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.media-home i {
  font-size: 3rem;
  color: #2494c1;
  margin: 10px;
}

.fab .fas {
  font-family: "Font Awesome 5 Brands";
}

h3 {
  font-family: "Nunito", sans-serif;
  padding: 0.5rem;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

p {
  padding: 1rem;
  /* margin-bottom: 25px; */
  text-align: left;
}

.card-tech {
  padding: 0;
  margin: 0;
}

.tech {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 25px;
}

.tech li {
  font-size: 3rem;
  padding: 1.5rem;
}

/* icons set color section */
.fa-html5 {
  color: #e34f26;
}
.fa-css3-alt {
  color: #016db5;
}
.fa-js-square {
  color: #fdda3e;
}
.fa-react {
  color: #5ed4f4;
}
.fa-node-js {
  color: #8cc13f;
}
.fa-database {
  color: #13a54f;
}

@media (min-width: 768px) {
  .content {
    min-height: 60vh;
  }
  .home {
    flex-direction: row;
    justify-content: center;
    margin-top: 8rem;
  }

  .davide-home {
    width: 45rem;
  }

  .davide-home h3 {
    text-align: left;
    font-style: italic;
    margin-top: 1rem;
  }

  p {
    padding: 0.5rem;
    font-size: 1.1rem;
  }

  .media-home {
    justify-content: left;
  }
}

.card {
  width: 22rem;
  min-height: 22rem;
  overflow: hidden;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 20px #eee, 0 6px 6px #d5d5d5;
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
}

.card h3 {
  font-family: nunito, sans-serif;
  text-align: center;
  padding: 1rem;
}

.card a {
  text-decoration: none;
  color: #000;
}

.card p {
  font-family: open sans, sans-serif;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 1px;
  padding: 15px;
  text-align: left;
}

.card p:nth-child(4) {
  flex: 1;
}

.image-bg {
  width: 22rem;
  height: 10rem;
  background-position: top;
}

.fcs {
  background-image: url(../../img/fcs_project.jpg);
}

.sv {
  background-image: url(../../img/sergiovacca_ss.jpg);
}

.fl {
  background-image: url(../../img/lom_project.jpg);
  background-size: cover;
}

.ba {
  background-image: url(../../img/ba_ss_r.jpg);
}

.bjc {
  background-image: url(../../img/bjc_ss.jpg);
}

.react-notes {
  background-image: url(../../img/react-note.jpg);
}

.js-notes {
  background-image: url(../../img/javaScriptNotes.jpg);
}

.kodflix {
  background-image: url(https://fontmeme.com/permalink/190623/850a74caf91dede3caa509e0dbf8d6b5.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
  width: 200px;
}

.weather-app {
  background-image: url(../../img/weather_app2.jpg);
}

.huddle {
  background-image: url(https://api.frontendmentor.io/wp-content/uploads/2018/10/desktop-preview-1-768x563.jpg);
  background-size: cover;
  background-position: center;
}

.fylo {
  background-image: url(https://api.frontendmentor.io/wp-content/uploads/2018/09/desktop-preview-768x563.jpg);
  background-size: cover;
  background-position: center;
}

.html-css-notes {
  background-image: url(https://res.cloudinary.com/dz209s6jk/image/upload/v1562939544/Challenges/bfsviv4bu5yxoxxhwbeq.jpg);
  background-size: cover;
  background-position: center;
}

.lyrics_bites {
  background-image: url(../../img/lyrics_bites2.JPG);
  background-size: cover;
  background-position: top;
  width: 95%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .card {
    margin: 3rem;
  }

  .card:hover {
    transform: translateY(-5px);
  }
}

.menubar {
  background-color: #da817a;
  position: fixed;
  top: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 100%;
  z-index: 1;
  padding: 0.5rem;
}

.menu li {
  padding: 5px;
  margin: 0 5px 0 5px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.menu a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.menu {
  top: 0px;
  left: 0px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .menubar {
    padding: 0;
  }

  .menu {
    display: flex;
    justify-content: center;
    background-color: #da817a;
    width: 100%;
    height: 50px;
    margin: 0 auto;
    border-top: 5px solid #f3b44b;
  }

  .menu li {
    margin-left: 25px;
    padding: 10px;
  }

  .menu li a {
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
  }
}

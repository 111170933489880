footer {
  background: #f0bb49;
  background-image: url(../../img/sfondo-header.jpg);
  width: 100%;
  background-position: center;
  background-size: cover;
  margin-top: 120px;
}

.footer-text {
  text-align: center;
  font-family: open sans, sans-serif;
  font-size: 1.1rem;
  color: #fff;
}

.App-header .media {
  justify-content: center;
}

.App-header .media i {
  font-size: 2.5rem;
  color: #fff;
  margin: 12px;
  opacity: 0.9;
}

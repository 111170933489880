.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
}

.dv {
  width: 45rem;
  height: 400px;
  background-color: #fff;
  margin-top: 7rem;
}

.dv h3 {
  font-family: "Nunito", sans-serif;
  padding: 0.5rem;
  text-align: center;
}

.dv p {
  padding: 1rem;
  text-align: justify;
  line-height: 20px;
}

.media {
  margin: 0.5rem;
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.media i {
  font-size: 3rem;
  color: #2494c1;
  margin: 10px;
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

@media (min-width: 768px) {
  .projects {
    margin-top: 8rem;
  }

  .media {
    justify-content: left;
    margin-top: 2rem;
  }

  .dv {
    margin: 3rem;
  }

  .david h1 {
    padding: 0.5rem;
  }

  .dv h3 {
    text-align: left;
  }

  .dv p {
    padding: 0.5rem;
  }
}

.tech1 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-end;
  font-size: 2.5rem;
}

.tech1 li .fab,
.tech1 li .fas {
  padding: 10px;
  margin: 10px;
}

.tech1 img {
  width: 40px;
}

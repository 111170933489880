@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
@import url(https://fonts.googleapis.com/css?family=Anton:400);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sub-title {
  font-family: "Open", sans-serif;
  font-size: 1.4rem;
}

.container {
  left: 50%;
  top: 35%;
  min-width: 375px;
  margin: 0 auto;
  position: absolute;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

h1 {
  font-family: "Open", sans-serif;
  font-weight: 700;
  font-size: 1.9em;
  font-weight: 400;
  margin-top: 0;
  letter-spacing: 2px;
}

.sub-title {
  text-align: center;
}

@media (min-width: 768px) {
  h1 {
    text-align: center;
    font-size: 4rem;
  }

  .sub-title {
    font-family: "Open", sans-serif;
    font-size: 1.8rem;
  }
}

.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
}

.dv {
  width: 45rem;
  height: 400px;
  background-color: #fff;
  margin-top: 7rem;
}

.dv h3 {
  font-family: "Nunito", sans-serif;
  padding: 0.5rem;
  text-align: center;
}

.dv p {
  padding: 1rem;
  text-align: justify;
  line-height: 20px;
}

.media {
  margin: 0.5rem;
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.media i {
  font-size: 3rem;
  color: #2494c1;
  margin: 10px;
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

@media (min-width: 768px) {
  .projects {
    margin-top: 8rem;
  }

  .media {
    justify-content: left;
    margin-top: 2rem;
  }

  .dv {
    margin: 3rem;
  }

  .david h1 {
    padding: 0.5rem;
  }

  .dv h3 {
    text-align: left;
  }

  .dv p {
    padding: 0.5rem;
  }
}

.card {
  width: 22rem;
  min-height: 22rem;
  overflow: hidden;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 10px 20px #eee, 0 6px 6px #d5d5d5;
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
}

.card h3 {
  font-family: nunito, sans-serif;
  text-align: center;
  padding: 1rem;
}

.card a {
  text-decoration: none;
  color: #000;
}

.card p {
  font-family: open sans, sans-serif;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 1px;
  padding: 15px;
  text-align: left;
}

.card p:nth-child(4) {
  flex: 1 1;
}

.image-bg {
  width: 22rem;
  height: 10rem;
  background-position: top;
}

.fcs {
  background-image: url(/static/media/fcs_project.32f1f995.jpg);
}

.sv {
  background-image: url(/static/media/sergiovacca_ss.c7a4c961.jpg);
}

.fl {
  background-image: url(/static/media/lom_project.b476d8f1.jpg);
  background-size: cover;
}

.ba {
  background-image: url(/static/media/ba_ss_r.7d6db2e5.jpg);
}

.bjc {
  background-image: url(/static/media/bjc_ss.f491911e.jpg);
}

.react-notes {
  background-image: url(/static/media/react-note.feb71e03.jpg);
}

.js-notes {
  background-image: url(/static/media/javaScriptNotes.2ecb16c1.jpg);
}

.kodflix {
  background-image: url(https://fontmeme.com/permalink/190623/850a74caf91dede3caa509e0dbf8d6b5.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
  width: 200px;
}

.weather-app {
  background-image: url(/static/media/weather_app2.58327d10.jpg);
}

.huddle {
  background-image: url(https://api.frontendmentor.io/wp-content/uploads/2018/10/desktop-preview-1-768x563.jpg);
  background-size: cover;
  background-position: center;
}

.fylo {
  background-image: url(https://api.frontendmentor.io/wp-content/uploads/2018/09/desktop-preview-768x563.jpg);
  background-size: cover;
  background-position: center;
}

.html-css-notes {
  background-image: url(https://res.cloudinary.com/dz209s6jk/image/upload/v1562939544/Challenges/bfsviv4bu5yxoxxhwbeq.jpg);
  background-size: cover;
  background-position: center;
}

.lyrics_bites {
  background-image: url(/static/media/lyrics_bites2.838a64ef.JPG);
  background-size: cover;
  background-position: top;
  width: 95%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .card {
    margin: 3rem;
  }

  .card:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
}

footer {
  background: #f0bb49;
  background-image: url(/static/media/sfondo-header.8cf58cc3.jpg);
  width: 100%;
  background-position: center;
  background-size: cover;
  margin-top: 120px;
}

.footer-text {
  text-align: center;
  font-family: open sans, sans-serif;
  font-size: 1.1rem;
  color: #fff;
}

.menubar {
  background-color: #da817a;
  position: fixed;
  top: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 100%;
  z-index: 1;
  padding: 0.5rem;
}

.menu li {
  padding: 5px;
  margin: 0 5px 0 5px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.menu a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.menu {
  top: 0px;
  left: 0px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .menubar {
    padding: 0;
  }

  .menu {
    display: flex;
    justify-content: center;
    background-color: #da817a;
    width: 100%;
    height: 50px;
    margin: 0 auto;
    border-top: 5px solid #f3b44b;
  }

  .menu li {
    margin-left: 25px;
    padding: 10px;
  }

  .menu li a {
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
  }
}

.home-background {
  background-image: url(/static/media/sfondo-header.8cf58cc3.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 80px 0;
  height: 100vh;
}

.home {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.media-home {
  margin: 0.5rem;
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.media-home i {
  font-size: 3rem;
  color: #2494c1;
  margin: 10px;
}

.fab .fas {
  font-family: "Font Awesome 5 Brands";
}

h3 {
  font-family: "Nunito", sans-serif;
  padding: 0.5rem;
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
}

p {
  padding: 1rem;
  /* margin-bottom: 25px; */
  text-align: left;
}

.card-tech {
  padding: 0;
  margin: 0;
}

.tech {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 25px;
}

.tech li {
  font-size: 3rem;
  padding: 1.5rem;
}

/* icons set color section */
.fa-html5 {
  color: #e34f26;
}
.fa-css3-alt {
  color: #016db5;
}
.fa-js-square {
  color: #fdda3e;
}
.fa-react {
  color: #5ed4f4;
}
.fa-node-js {
  color: #8cc13f;
}
.fa-database {
  color: #13a54f;
}

@media (min-width: 768px) {
  .content {
    min-height: 60vh;
  }
  .home {
    flex-direction: row;
    justify-content: center;
    margin-top: 8rem;
  }

  .davide-home {
    width: 45rem;
  }

  .davide-home h3 {
    text-align: left;
    font-style: italic;
    margin-top: 1rem;
  }

  p {
    padding: 0.5rem;
    font-size: 1.1rem;
  }

  .media-home {
    justify-content: left;
  }
}

.side-projects {
  min-height: 100vh;
}

.sideProject-h2 {
  text-align: center;
  margin-top: 8rem;
  font-family: "Anton";
  font-size: 2.5rem;
}

.side-projects .projects {
  margin-top: 3rem;
}

.side-projects p {
  text-align: center;
}

.App-header .media {
  justify-content: center;
}

.App-header .media i {
  font-size: 2.5rem;
  color: #fff;
  margin: 12px;
  opacity: 0.9;
}

.tech1 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-end;
  font-size: 2.5rem;
}

.tech1 li .fab,
.tech1 li .fas {
  padding: 10px;
  margin: 10px;
}

.tech1 img {
  width: 40px;
}

.mentor {
  min-height: 100vh;
}

.mentor-h2 {
  text-align: center;
  margin-top: 8rem;
  font-family: "Anton";
  font-size: 2.5rem;
}

.mentor .projects {
  margin-top: 3rem;
}

.mentor p {
  text-align: center;
  margin: 1rem;
}

a {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

.App h1 {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.david h1 {
  font-family: "Anton", sans-serif;
  font-size: 3rem;
  padding: 0.5rem;
}

p {
  line-height: 20px;
}

@media (min-width: 768px) {
  .App h1,
  p {
    text-align: left;
  }

  .App-header {
    height: 100vh;
  }

  .sub-title {
    font-family: open, sans-serif;
    font-size: 1.8rem;
    margin-top: 15px;
  }
}


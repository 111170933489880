.mentor {
  min-height: 100vh;
}

.mentor-h2 {
  text-align: center;
  margin-top: 8rem;
  font-family: "Anton";
  font-size: 2.5rem;
}

.mentor .projects {
  margin-top: 3rem;
}

.mentor p {
  text-align: center;
  margin: 1rem;
}

a {
  text-decoration: none;
}
